import React, {ReactElement} from "react";
import {SearchListInterface} from "./Types";
import FilterItem from "./FilterItem";

export function SearchList({search, propertyName}: SearchListInterface): ReactElement {
    return (
        <>
            {search.dataList.map((filter: any, key: number) => {
                return <FilterItem
                    propertyName={propertyName}
                    filter={
                        propertyName === 'categories' ?
                            {name: filter.hurt_name, id: filter.id, products_quantity: filter.products_quantity}
                            :
                            filter
                        }
                    key={key}
                />
            })}
        </>
    );
}