import {Link, useLoaderData, useNavigate, useParams} from "react-router-dom";
import UserAccount from "../../../Components/UserAccount";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "../../../Assets/Css/Pages/User/SavedCart/savedCart.css"
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";
import {SavedCartModal} from "./TransferSavedCartToCart";
import {ListItemHelper} from "../../ProductsList/ListItemHelper";
import {useProductData} from "../../ProductsList/ProductData";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import {DELETE, GET, PATCH} from "../../../Hooks/RequestV2";
import {ChannelContext} from "../../../Contexts/ChannelContext";
import {Throbber} from "../../../Components/Throbber";
import {IconsDesignation} from "../../ProductsList/IconsDesignation";
import {QuantityDisposition} from "../../ProductsList/Components/QuantityDisposition";
import { ScreenWidthContext } from "../../../Contexts/ScreenWidthContext";
import { ProductsListContext } from "../../../Contexts/ProductsListContext";
import MatrixList from "../../ProductsList/ListType/MatrixList";


function SavedCartMatrixList({savedCart, setSavedCart, reloadCart, setThrobber}){



    return <div className="matrix">{savedCart.cartItem.filter(item => item.hasOwnProperty('helperProductData')).map((cartItem,index) => <Item
            index={index}
            data={cartItem}
            cart={savedCart}
            setCart={setSavedCart}
            reloadCart={reloadCart}
            setThrobber={setThrobber}
            key={cartItem.id} />
    )}
    </div>
}

function SavedCart(){
    const loader                        = useLoaderData();
    const [savedCart, setSavedCart]     = useState(loader.saved_cart);
    const {id}                          = useParams();
    const {i18n, t}                     = useTranslation();
    const {user}                        = useContext(UserContext);
    const channelContext                = useContext(ChannelContext);
    const [throbber, setThrobber]       = useState(false);
    const navigate                      = useNavigate();
    const [cartName, setCartName]       = useState(savedCart.name);
    const [sum, setSum]                 = useState({netto: loader.summary.sum_netto, brutto: loader.summary.sum_brutto, total: loader.summary.total});
    const screenWidth = useContext(ScreenWidthContext)

    function reloadCart(){
        GET('/user/saved-cart/'+id)
            .then((response) => {
                if (response.status === 404){
                    setThrobber(false);
                    navigate('/user/saved-carts');
                }
                return response.json();
            })
            .then((json) => {
                setThrobber(false);
                setSavedCart(json.saved_cart);
                setSum({netto: json.summary.sum_netto, brutto: json.summary.sum_brutto, total: json.summary.total});
            });
    }

    function updateCartName(name){
        setCartName(name);
        PATCH('/user/saved-card/', {cart_id: savedCart.id, name});
    }

    if (user === null){
        return (
            <UserIsNotLogged />
        );
    }

    if (savedCart === null){
        return(
            <></>
        );
    }

    const breadcrumb = [{name: t('user-page.saved-carts.title'), path: '/user/saved-carts'}, {name: cartName, path: null}];

    return(
        <UserAccount title={t('user-page.saved-cart.cart-name')+' '+cartName} className="my-saved-cart" breadcrumb={breadcrumb} >
            <Throbber visible={throbber} />
            <div className={'cart-name-container'} >
                <span>Zmień nazwę koszyka:</span>
                <input
                    type={'text'}
                    name={'cart-name'}
                    value={cartName}
                    onChange={(e) => {
                        updateCartName(e.target.value);
            }} />
            </div>

            {screenWidth < 800 ?  <SavedCartMatrixList {...{savedCart, setSavedCart, reloadCart, setThrobber}}/> : <div className="tabular">
                <div className="header" >
                    <div className="thumb" >{t('products-list.thumb')}</div>
                    <div className="name" >{t('products-list.name')}<br/>{t('products-list.index-et')} | {t('products-list.ean')}<br/>{t('products-list.producer')}</div>
                    <div className="quantity" >{t('products-list.avail')}</div>

                    <div className="before-discount" >{t('products-list.before-discount')}</div>
                    <div className="discount" >{t('products-list.discount')}</div>
                    <div className="price" >{t('products-list.price-after-discount')}</div>

                    <div className={'total'} >
                        Wartość
                    </div>

                    <div className="package" >{t('products-list.method-packing')}</div>
                    <div className="sale" >{t('products-list.quantity')}</div>
                </div>
                {savedCart.cartItem.filter(item => item.hasOwnProperty('helperProductData')).map((cartItem,index) => {

                    return <Item
                        index={index}
                        data={cartItem}
                        cart={savedCart}
                        setCart={setSavedCart}
                        reloadCart={reloadCart}
                        setThrobber={setThrobber}
                        key={cartItem.id} />
                })}
            </div>
            }
            {user && channelContext?.displayPriceLogged === true ? null :
                <>
                    <div className={'total'} >
                        <div className={'summary'} >
                            <div className={'price'} >
                                <strong>Łączna wartość koszyka:</strong>
                                <span style={{marginRight: 2}} >{sum.netto.toFixed(2)} {t('products-list.before-tax')}</span>
                                <span>{sum.brutto.toFixed(2)} {t('products-list.after-tax')}</span>
                            </div>
                            <div className={'quantity'} >
                                <strong style={{marginRight: 2}} >Łączna ilość pozycji w koszyku:</strong>
                                <span>{savedCart.cartItem.length}</span>
                            </div>
                        </div>
                    </div>

                    <div className={'buttons'} >
                        <SavedCartModal setThrobber={setThrobber} endpoint={'/user/saved-cart/transfer'} idItem={savedCart.id} />
                    </div>
                </>
            }


        </UserAccount>
    );
}

function Item({index,data, cart, setCart, reloadCart, setThrobber}){
    const product                           = useProductData(data.helperProductData);
    const [itemQuantity, setItemQuantity]   = useState(data.quantity);
    const {t}                               = useTranslation();
    const ref                               = useRef(null);
    const {visibility, toggleVisibleModal}  = useSimpleModalState();
    const [totall, setTotall]               = useState(calcTotal());

    const listItemHelper    = new ListItemHelper();
    const iconsDesignation  = new IconsDesignation(product, t);
    const {user}            = useContext(UserContext);
    const channelContext           = useContext(ChannelContext);

    const screenWidth = useContext(ScreenWidthContext);

    function calcTotal(){
        const netto = (itemQuantity * product.quantityPackage) * product.priceNetto;
        const brutto = (itemQuantity * product.quantityPackage) * product.priceBrutto;

        return {netto, brutto}
    }

    useEffect(() => {
        setTotall(calcTotal());
    }, [itemQuantity])

    function removeItem(productId){
        setThrobber(true);
        DELETE('/user/saved-cart/remove-item', {cart_id: cart.id, product_id: productId})
            .then((response) => {
                if (response.status === 204){
                    reloadCart();
                }
            });
    }

    function displayPrice() {
        if (!user && channelContext?.displayPriceNotLogged === true) {
            return t('products-list.disable-price-not-logged')
        }

        if (user && channelContext?.displayPriceLogged === true) {
            return t('products-list.disable-price-logged')
        }

        return (
            <>
                <div className="netto" >{product.priceNetto > 0 ? product.priceNetto.toFixed(2) : product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div>
                <div className="brutto" >{product.priceBrutto > 0 ? product.priceBrutto.toFixed(2) : product.priceBeforeBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
            </>
        );
    }

    function displayQuantity() {
        if (!user && channelContext?.displayQuantityNotLogged === true){
            return t('products-list.disable-quantity-not-logged')
        }

        if (user && channelContext?.displayQuantityLogged === true){
            return t('products-list.disable-quantity-logged')
        }

        return <QuantityDisposition quantity={product.quantity} unit={product.unit} />;
    }

    function updateItem(itemId, quantity){
        product.ref.current.value = Number(quantity * product.quantityPackage).toFixed(0)+' '+ product.unit;

        setItemQuantity(quantity);


        PATCH('/user/saved-cart/update-item', {item_id: itemId, quantity})
            .then((response) => {
                if (response.status === 204){
                    reloadCart();
                }
            });
    }


    return(
        <>
            <SimpleModal visibility={visibility} hidden={toggleVisibleModal} className={'product-thumb-modal'} >
                <img src={product.bigThumb} className={'thumb-img'} />
            </SimpleModal>

            <div className={((index+1) % 2 == 0) ? "product even" : "product"} ref={ref} >
                <div className={'thumb-container'} >
                    <div className="thumb" style={{backgroundImage: 'url('+product.thumb+')'}} onClick={() => toggleVisibleModal()} >
                        <i className="fas fa-search-plus more" />
                        {iconsDesignation.getIcons()}
                    </div>
                </div>
                { !(screenWidth < 700) ?  <div className="name" >
                    <Link to={'/product/'+product.slug} >
                        <h2>{product.name}</h2>
                    </Link>
                    <div className="index" >{t('products-list.index-et')} {product.indexHurt} | {t('products-list.ean')} {product.ean}</div>
                    <div className="producer" >{t('products-list.producer')} {product.producerName}</div>
                </div> : <>
                <div className="name" >
                    <Link to={'/product/'+product.slug} >
                        <h2>{product.name}</h2>
                    </Link>
                </div>
                <div className="index" >{t('products-list.index-et')} {product.indexHurt} | {t('products-list.ean')} {product.ean}</div>
                <div className="producer" >{t('products-list.producer')} {product.producerName}</div>
                </>}

                <div className="quantity" >{displayQuantity()}</div>

                <div className="before-discount" >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            <div className="netto" >{product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.priceBeforeBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
                        </>
                    }
                </div>

                <div className="discount" >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            {parseInt(product.discount)}%
                        </>
                    }

                    {product.isNoDiscount ? <div className={'final-price'} >{t('products-list.final-price')}</div> : ''}
                </div>

                <div className="price" >
                    {displayPrice()}
                </div>

                <div className={'total'} >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            <div className="netto" >{product.totalNetto} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.totalBrutto} {t('products-list.after-tax')}</div>
                        </>
                    }
                </div>

                <div className="package" >
                    {(!user && channelContext?.displayQuantityNotLogged === true) || (user && channelContext?.displayQuantityLogged === true) ? null :
                        <>{ product.quantityPackage}</>
                    }
                </div>
                <div className="sale" >

                    {user && channelContext?.displayPriceLogged === true ? null :
                        <div className="add-to-cart" >
                            <div className="field" >
                                <input
                                    type="text"
                                    name="quantity-package"
                                    disabled={true}
                                    value={(itemQuantity !== 0 ? Number(itemQuantity * product.quantityPackage).toFixed(0)+' '+ product.unit : '')}
                                    ref={product.ref} />
                            </div>
                            <div className="field" >
                                <input
                                    type="number"
                                    name="quantity"
                                    step={channelContext.IncompletePackaging ? 0.1 : 1}
                                    min="0"
                                    value={(itemQuantity !== 0 ? itemQuantity : '')}
                                    onChange={(e) => {
                                        e.currentTarget.blur();
                                        updateItem(data.id, e.target.value);
                                    }} />
                            </div>

                            <button className="btn red item-remove-button" onClick={() => removeItem(product.id)}  >
                                <i className="fas fa-times" ></i> {t('cart.remove')}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default SavedCart;
