import React, {ReactElement, useEffect, useState} from "react";
import {FilterInterface, PromotedInterface} from "./Types";
import FilterItem from "./FilterItem";

export function Promoted({data, propertyName}: PromotedInterface): ReactElement {
    const [promoted, setPromoted] = useState<FilterInterface[]>([]);

    useEffect(() => {
        data.map((filter: FilterInterface, key: number) => {
            if (filter.promoted){
                setPromoted((prevState) => [...prevState, filter]);
            }
        });
    }, []);

    return (
        <>
            {promoted.map((filter: any, key: number) => {
                return <FilterItem
                    propertyName={propertyName}
                    filter={filter}
                    key={key}
                />
            })}
        </>
    );
}