import MatrixList from "./ListType/MatrixList";
import {ProductsPerPage, Sort, ListTypeNav, ProductsCount, Pagination, DownloadXML} from "./ProductsListComponents";
import {useCookie} from "../../Hooks/Cookies";
import NormalList from "./ListType/NormalList";
import TabularList from "./ListType/TabularList";
import {POST} from "../../Hooks/RequestV2";
import {buildLoaderRequest} from "./FiltersDataHelper";
import React, {useContext, useEffect, useState} from "react";
import {
    ScrollRestoration,
    useLoaderData,
    useNavigate,
    useNavigation,
    useParams,
    useSearchParams
} from "react-router-dom";
import {Throbber} from "../../Components/Throbber";
import {ProductsListContext} from "../../Contexts/ProductsListContext";
import {Tags} from "./Components/Tags"
import {useFormContext, useWatch} from "react-hook-form";
import {OldPlatformStyle} from "./ListType/OldPlatformStyle";
import {ScreenWidthContext} from "../../Contexts/ScreenWidthContext";

function List({filters, globalSend, mobileMenuToggle, setMobileMenuToggle}){

    const {getCookie}       = useCookie();
    const params            = useParams();
    const watch             = useWatch();
    const listTypeCookie    = (getCookie('list-type') ? getCookie('list-type') : 'old-platform');

    const [loading, setLoading]     = useState(false);
    const [products, setProducts]   = useState(filters.products);
    const [additionalInformation, setAdditionalInformation] = useState(filters);

    const [listType, setListType]           = useState(listTypeCookie);
    const [searchParams, setSearchParams]   = useSearchParams();
    const [activePage, setActivePage]       = useState((searchParams.get('page') === null ? 1 : parseInt(searchParams.get('page'))));
    const screenWidth = useContext(ScreenWidthContext);

    useEffect(() => {
        setAdditionalInformation(filters);
        setProducts(filters.products);

        setActivePage((searchParams.get('page') === null ? 1 : parseInt(searchParams.get('page'))));
    }, [filters]);

    function refreshProductsList(){
        const url = window.location.origin+window.location.pathname+(window.location.search ? window.location.search : '');

        setLoading(true);

        POST('/filters/products-list', buildLoaderRequest(params, url))
            .then((response) => { return response.json(); })
            .then((json) => {

                setAdditionalInformation(json.productsList);
                setProducts(json.productsList.products)
                setLoading(false);
            });
    }

    function returnListByType(){
        if (screenWidth < 1200){
            return <MatrixList />;
        }

        if (listType === 'matrix'){
            return <MatrixList />
        } else if (listType === 'list') {
            return <NormalList  />
        } else if (listType === 'old-platform') {
            return <OldPlatformStyle />
        } else {
            return <TabularList />
        }
    }

    return(
        <>
            <div className="list" >
                <Tags send={globalSend} />
                <div className="container" >
                    <ProductsPerPage send={refreshProductsList} />
                    <DownloadXML />
                    <Sort send={refreshProductsList} />
                    <ListTypeNav type={listType} setType={setListType} />
                </div>

                {screenWidth < 1200 ?
                    <div className={'container-mobile-filter-button'} >
                        <button className={'btn mobile-filter-button'} onClick={() =>setMobileMenuToggle(true)} >Filtry</button>
                    </div> : null}
                
                <div className="container bottom" >
        
                    {additionalInformation.total == 0 &&<ProductsCount count={additionalInformation.total} />}
                    {additionalInformation.total != 0 && <><div/><Pagination count={additionalInformation.total} send={refreshProductsList} activePage={activePage} setActivePage={setActivePage}  /></>}
                </div>
                <Throbber visible={loading} />
                <div className={'products'+(loading ? ' hidden' : '')} >
                    <ProductsListContext.Provider value={{products: products || [], setProducts: setProducts}} >
                        {returnListByType()}
                    </ProductsListContext.Provider>
                </div>
                
                {additionalInformation.total != 0 && <div className="container bottom" >
                {additionalInformation.total == 0 &&   <ProductsCount count={additionalInformation.total} />}
                    <Pagination count={additionalInformation.total} send={refreshProductsList} activePage={activePage} setActivePage={setActivePage} />
                </div>}
                
            </div>
        </>
    );
}

export default List;
