import React, {ReactElement, useContext, useEffect, useState} from "react";
import {FiltersToggleStateInterface} from "../Menu";
import {useTranslation} from "react-i18next";
import {useSearch, useSearchCategory} from "../../../HeaderMenu";
import {Link} from "react-router-dom";
import {AlphabetFilterPropsInterface} from "./Types";
import {AlphabetList} from "./AlphabetList";
import {SearchList} from "./SearchList";
import {Promoted} from "./PromotedItems";
import {CategoryList} from "./CategoryList";
import {ActiveMenuContext} from "../../Header";

export default function AlphabetFilter({data, label, propertyName, toggleState, search}: AlphabetFilterPropsInterface): ReactElement {
    const [letterToggle, setLetterToggle] = useState<Record<string, boolean>>({});
    const {t} = useTranslation();
    const activeMenuContext = useContext(ActiveMenuContext);

    return (
        <>
            <button onClick={() => {
                toggleState.setFiltersToggleState((prevState: FiltersToggleStateInterface) => {

                    return {...prevState, [propertyName]: !prevState[propertyName]};
                })
            }} >{label}</button>

            <ul className={toggleState.filtersToggleState[propertyName] ? 'active' : undefined} >

                <div className={'options'} >
                    <input onChange={(e) => search.states.setSearch(e.currentTarget.value)}
                           type="search"
                           name="search"
                           placeholder={t('layout.menu.tools.search')}
                           autoComplete="off" />

                    <div className={'view-list'} >
                        <Link to={'/filter/'+propertyName} onClick={() => activeMenuContext?.setActiveMenu(false)} >{t('layout.menu.show-all')}</Link>
                    </div>
                </div>


                <div className={'promoted'} >
                    <Promoted data={data} propertyName={propertyName} />
                </div>



                {search.states.emptyResultsSearching && search.states.search ?

                    <div className={'empty'} >Nie znaleziono frazy</div>

                    :

                    <>
                        {search.states.clickableFirstMenu && search.states.search ?
                            <SearchList
                                search={search}
                                propertyName={propertyName}
                            />
                            :

                            <>
                                {propertyName === 'categories' ?

                                    <CategoryList
                                        search={search}
                                        toggleState={{letterToggle, setLetterToggle}} />

                                    :

                                    <AlphabetList
                                        search={search}
                                        toggleState={{letterToggle, setLetterToggle}}
                                        propertyName={propertyName} />
                                }
                            </>
                        }
                    </>
                }

            </ul>
        </>
    );
}