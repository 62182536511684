import React, {RefObject} from "react";
import {CategoriesFilterItem} from "./index";
import {MergeAlphabetical} from "../../Components/ScrollMenu/MergeAlphabetical";
import {Link, useParams} from "react-router-dom";
import { customWordBreak } from "./punctuation";

interface Props {
    data: CategoriesFilterItem[]
}

function CategoriesFilter({data}: Props): React.ReactElement {
    return(
        <div className={'categories-list'} >
            {
                data.map((mainCategory: CategoriesFilterItem, mainKey: number) => {
                    return (
                        <div className={'main-category'} key={mainKey} >
                            <div className={'title'} >
                                <Link to={'/products-list/categories/'+mainCategory.id} >{mainCategory.hurt_name}</Link>
                            </div>

                            <div className={'category-filters'} >
                                {
                                    mainCategory.children?.map((child: CategoriesFilterItem, childKey: number) => {
                                        return (
                                            <div className={'filter'} key={mainKey+'-'+childKey} >
                                                <Link to={'/products-list/categories/'+child.id} >
                                                    <span className={'name'}  >{customWordBreak(child.hurt_name)}</span>
                                                    <span className={'quantity'} >({child.products_quantity})</span>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export {CategoriesFilter};