import React, {ReactElement, useContext} from "react";
import FilterItem from "./FilterItem";
import {CategoryListInterface} from "./Types";
import {Link} from "react-router-dom";
import {ActiveMenuContext} from "../../Header";

export function CategoryList({toggleState, search}: CategoryListInterface): ReactElement {
    const {setLetterToggle, letterToggle} = toggleState;

    const activeMenuContext = useContext(ActiveMenuContext);

    return (
        <>
            {search.alphabetData.map((cat1: any, key: number) => {
                return (
                    <li key={key} >
                        <div className={'cat-buttons'} >
                            <button onClick={() => {
                                setLetterToggle((prevState: {[key: string]: boolean}) => {
                                    return {...prevState, [cat1.id]: !prevState[cat1.id]};
                                });
                            }} >
                                {cat1.hurt_name} <span>({cat1.products_quantity})</span>
                            </button>
                            {letterToggle[cat1.id] ?

                                <div className={'category-parent-anchor'}  >
                                    <Link to={'/products-list/categories/'+cat1.id} onClick={() => activeMenuContext?.setActiveMenu(false)} >
                                        Pokaż produkty
                                    </Link>
                                </div>

                                : null}
                        </div>


                        <ul className={letterToggle[cat1.id] ? 'active' : undefined} >

                            {cat1.children.map((cat2: any, key2: number) => {
                                return (
                                    <li key={key2}>
                                        <div className={'cat-buttons'} >
                                            <button onClick={() => {
                                                setLetterToggle((prevState: {[key: string]: boolean}) => {
                                                    return {...prevState, [cat2.id]: !prevState[cat2.id]};
                                                });
                                            }} >{cat2.hurt_name} <span>({cat2.products_quantity})</span></button>

                                            {letterToggle[cat2.id] ?

                                            <div className={'category-parent-anchor'}  >
                                                <Link to={'/products-list/categories/'+cat1.id} onClick={() => activeMenuContext?.setActiveMenu(false)} >
                                                    Pokaż produkty
                                                </Link>
                                            </div>

                                                : null }
                                        </div>


                                        <ul className={letterToggle[cat2.id] ? 'active' : undefined}  >
                                            {cat2.children.map((cat3: any, key3: number) => {
                                                return <FilterItem
                                                    propertyName={'categories'}
                                                    filter={{name: cat3.hurt_name, id: cat3.id, products_quantity: cat3.products_quantity}}
                                                    key={key3}
                                                />
                                            })}
                                        </ul>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                );
            })}
        </>
    );
}