import {ReactElement, useContext, useState} from "react";
// @ts-ignore
import logo from "../../Assets/Images/logo.png";
import {Link} from "react-router-dom";
import {HeaderMenu} from "./HeaderMenu";
import {CartHeader, SearchHeader} from "./HeaderMiddle";
import {UserContext, UserContextInterface} from "../../Contexts/UserContext";

function headerScrollVisibleToggle(setVisible: any, precision: number): void{
    window.addEventListener('scroll', (e) => {
        const header = document.querySelector('header');

        header!.offsetHeight < (window.scrollY - precision) ? setVisible(true) : setVisible(false);
    });
}

function HeaderScroll(): any {
    const [visible, setVisible] = useState<boolean>(false);
    const userContext = useContext(UserContext);

    headerScrollVisibleToggle(setVisible, 25);

    return (
        <div className={'header-scroll'+(visible ? ' visible': '')+(userContext?.user !== false ? ' logged' : '')} >
            <div className={'logo'} >
                <Link to="/" >
                    <img src={logo} alt="Logo EuroTrade" />
                </Link>
            </div>
            <HeaderMenu/>
            <SearchHeader small={true} />
            <div className={'cart'} >
                {userContext?.user !== false ? <CartHeader /> : ''}
            </div>
        </div>
    );
}

export {HeaderScroll, headerScrollVisibleToggle}