import {
    ScrollRestoration,
    useLoaderData,
    useParams,
    useSearchParams
} from "react-router-dom";
import Breadcrumb from "../../Components/Breadcrumb";
import Filters from "./Filters";
import List from "./List";
import React, {useContext, useEffect, useState} from "react";
import '../../Assets/Css/Pages/ProductsList/products_list.css'
import {useForm, FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {collectDataForm, getSearchFilterFromURL, mappingURLtoForm} from "./FiltersDataHelper";
import {useForceReload} from "../../Hooks/ForceReload";
import {BuildResetFiltersObject} from "./Tools/BuildResetFiltersObject";
import {ProductsAndFiltersInterface} from "./DataInterface";

function ProductsList() {

    const loader: any   = useLoaderData();
    const {filter, id}  = useParams<`filter` | `id`>();

    const buildResetFilterObject = new BuildResetFiltersObject(mappingURLtoForm({name: filter, id}));

    const [data, setData]                   = useState<ProductsAndFiltersInterface>(loader);
    const {t}                               = useTranslation();
    const [searchParams, setSearchParams]   = useSearchParams();
    const form                              = useForm({defaultValues: buildResetFilterObject.getRebuildObject()});
    const [mobileMenuToggle, setMobileMenuToggle] = useState(false);

    let breadcrumb = [];

    useForceReload(send);

    useEffect(() => {
        setData(loader);

        buildResetFilterObject.urlObject = mappingURLtoForm({name: filter, id});
        form.reset(buildResetFilterObject.getRebuildObject());
    }, [loader]);

    async function send() {
        collectDataForm(form, {name: filter, id}, setSearchParams);
    }

    function resetAllForm() {
        setSearchParams(getSearchFilterFromURL());

        buildResetFilterObject.urlObject = mappingURLtoForm({name: filter, id});
        form.reset(buildResetFilterObject.getRebuildObject());
    }

    for (let x = 0; x < data?.productsList.breadcrumb.length; x++) {
        breadcrumb.push({
            name: t(data?.productsList.breadcrumb[x].name),
            path: data?.productsList.breadcrumb[x].pathname
        });
    }

    return (
        <>
            <Breadcrumb breadcrumb={breadcrumb}/>
            <div className="products-list">
                <FormProvider {...form} >
                    <Filters
                        filters={data.filters}
                        send={send}
                        resetAllForm={resetAllForm}
                        mobileMenuToggle={mobileMenuToggle}
                        setMobileMenuToggle={setMobileMenuToggle}
                    />
                    <List
                        filters={data.productsList}
                        globalSend={send}
                        mobileMenuToggle={mobileMenuToggle}
                        setMobileMenuToggle={setMobileMenuToggle}
                    />
                </FormProvider>
            </div>
        </>
    );
}

export default ProductsList;
