import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {GET} from "../../../../Hooks/RequestV2";
import AlphabetFilter from "./Components/AlphabetFilter";
import {useSearch, useSearchCategory} from "../../HeaderMenu";
import {ActiveMenuContext} from "../Header";

const emptyFilters = {
    filters: {
        categories: [],
        producers: [],
        brands: [],
        licenses: [],
        logs: {}
    },
    logs: {}
};

const filtersToggleDefaultSate = {
    categories: false,
    producers: false,
    brands: false,
    licenses: false
};

export interface FiltersToggleStateInterface {
    categories: boolean,
    producers: boolean,
    brands: boolean,
    licenses: boolean
};

interface MobileMenuInterface {
    activeState: {
        activeMenu: boolean,
        setActiveMenu: React.Dispatch<boolean>
    }
}

export default function MobileMenu(): ReactElement {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState(emptyFilters);
    const activeState = useContext(ActiveMenuContext);

    const [filtersToggleState, setFiltersToggleState] = useState<FiltersToggleStateInterface>(filtersToggleDefaultSate);

    useEffect(() => {
        GET('/filters/menu')
            .then((response) => {
                if (response.ok){
                    response.json().then(setFilters);
                }
            });
    }, []);

    return (
        <nav className={activeState?.activeMenu ? 'active' : undefined} >
            <div className={'wrapper'} >
                <button onClick={() => activeState?.setActiveMenu(false)} className={'close-menu'} >
                    <i className={'fas fa-times'} />
                </button>
                <ul>
                    <li>
                        <AlphabetFilter
                            data={filters.filters.categories}
                            label={t('layout.menu.categories')}
                            propertyName={'categories'}
                            search={useSearchCategory(filters.filters.categories)}
                            toggleState={{filtersToggleState, setFiltersToggleState}}
                        />
                    </li>
                    <li>
                        <AlphabetFilter
                            data={filters.filters.producers}
                            label={t('layout.menu.producers')}
                            propertyName={'producers'}
                            search={useSearch(filters.filters.producers)}
                            toggleState={{filtersToggleState, setFiltersToggleState}}
                        />
                    </li>
                    <li>
                        <AlphabetFilter
                            data={filters.filters.brands}
                            label={t('layout.menu.brands')}
                            propertyName={'brands'}
                            search={useSearch(filters.filters.brands)}
                            toggleState={{filtersToggleState, setFiltersToggleState}}
                        />
                    </li>
                    <li>
                        <AlphabetFilter
                            data={filters.filters.licenses}
                            label={t('layout.menu.licenses')}
                            propertyName={'licenses'}
                            search={useSearch(filters.filters.licenses)}
                            toggleState={{filtersToggleState, setFiltersToggleState}}
                        />
                    </li>
                    <li>
                        <button onClick={() => {
                            activeState?.setActiveMenu(false);
                            navigate('/products-list/news')
                        }} >{t('layout.menu.news')}</button>
                    </li>
                    <li>
                        <button onClick={() => {
                            activeState?.setActiveMenu(false);
                            navigate('/products-list/promotions');
                        }} >{t('layout.menu.promotions')}</button>
                    </li>
                    <li>
                        <button onClick={() => {
                            activeState?.setActiveMenu(false);
                            navigate('/products-list/sales');
                        }} >{t('layout.menu.sales')}</button>
                    </li>
                </ul>
            </div>
        </nav>
    );
}