import React, {Dispatch, SetStateAction, useContext, useMemo, useState} from "react";
import {Currencies, Languages} from "../HeaderTop";
import {CartHeader, SearchHeader} from "../HeaderMiddle";

// @ts-ignore
import logo from "../../../Assets/Images/logo.png";
import {Link} from "react-router-dom";
import {LoginForm} from "../../../Forms/LoginForm";
import {ChannelContext} from "../../../Contexts/ChannelContext";
import {UserContext} from "../../../Contexts/UserContext";
import MobileMenu from "./Menu/Menu";
import {ChannelType} from "../../../Types/ChannelType";
import { headerScrollVisibleToggle } from "../HeaderScroll";
import useFloatingMobileHeader from "../../../Hooks/useFloatingMobileHeader";

interface activeMenuContextInterface {
    activeMenu: boolean,
    setActiveMenu: React.Dispatch<boolean>
}

interface advanceVisibleContextInterface {
    0: boolean,
    1: Dispatch<SetStateAction<boolean>>
}

export const AdvanceVisibleContext = React.createContext<advanceVisibleContextInterface>([false, ()=>{}]);
export const ActiveMenuContext = React.createContext<activeMenuContextInterface|null>(null);

function MobileHeader() {
    const channelContext = useContext(ChannelContext);
    const userContext = useContext(UserContext);
    const [advanceVisible, setAdvanceVisible] = useState(false)
    const [activeMenu, setActiveMenu] = useState(false);
    const {visible } = useFloatingMobileHeader();

    return(
        <header className={`mobile ${visible ? "visible" : ""} ${advanceVisible ? "advance-visible" : ""}`} >

            {userContext && userContext?.user && userContext?.user?.admin ?

                <div className={'logged-as-contractor'} >
                    <span className={'title'} >Działasz w imieniu:</span> <span className={'value'} >[{userContext.user.receiver.contractor.hurtId}] {userContext.user.receiver.contractor.name}</span>
                </div>

                : null}
            <AdvanceVisibleContext.Provider value={[advanceVisible, setAdvanceVisible]}>
                <div className={'top'} >
                    <Link to="/" className={'logo'} >
                        <img src={logo} alt="Logo EuroTrade" />
                    </Link>
                    <div className={'icons'} >
                        {/*@ts-ignore*/}
                        {channelContext && channelContext?.currency.length > 1 ? <Currencies /> : null}
                        {channelContext && channelContext?.locales.length > 1 ? <Languages /> : null}
                        <LoginForm />
                    </div>
                </div>
                <div className={'bottom'} >
                    <button className={'filter-trigger'} onClick={() => setActiveMenu(true)} >
                        <i className="fas fa-bars"></i>
                    </button>

                    <div className={'middle'} >
                        <div className={'search'} >
                            <SearchHeader small={false} />
                        </div>

                        <div className="cart" >
                            {userContext?.user ? <CartHeader /> : null}
                        </div>
                    </div>
                </div>
            </AdvanceVisibleContext.Provider>
            <ActiveMenuContext.Provider value={{activeMenu, setActiveMenu}} >
                <MobileMenu />
            </ActiveMenuContext.Provider>

        </header>
    );
}

export default MobileHeader;
