import {useContext, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useAdd2Cart} from "../../Requests/Cart";
import config from "../../Requests/RequestConfig.json";
import imageNotFound from "../../Assets/Images/image-not-found.png";
import {CartContext} from "../../Contexts/CartContext";
import {toLower} from "lodash";


function useProductData(data){
    const calculatedInputRef    = useRef(null);
    const defaultImage          = imageNotFound;
    const {t}                   = useTranslation();

    let thumb, bigThumb;
    let images = [];

    if (data === null){
        return null;
    }

    thumb = (data.thumb === null ? defaultImage : config.domain+'/assets/img/thumbnail/'+data.thumb);
    bigThumb = (data.thumb === null ? defaultImage : config.domain+'/assets/img/'+data.thumb);

    let isGallery;
    if (data.gallery?.length > 0){
        isGallery = true;
        images = data.gallery.map((value) => {
            return config.domain+'/assets/img/'+value;
        });
    } else {
        isGallery = false;
        images.push(defaultImage);
    }

    if (thumb === defaultImage && data.gallery?.length > 0){
        thumb = config.domain+'/assets/img/thumbnail/'+data.gallery[0];
        bigThumb = config.domain+'/assets/img/'+data.gallery[0];
    }

    let totalNetto, totalBrutto;

    if (data.price === null){
        totalBrutto = 0;
        totalNetto = 0;
        data.price = {};
        data.price.isPromoted = false;
        data.price.priceBeforeDiscountNetto = 0;
        data.price.priceBeforeDiscountBrutto = 0;
        data.price.priceAfterDiscountNetto = 0;
        data.price.priceAfterDiscountBrutto = 0;
        data.price.discount = 0;
        data.price.priceType = '';

    } else {
        if (data.price.priceAfterDiscountNetto > 0){
            totalNetto = (data.cartItemQuantity * data.quantityInPackage) * parseFloat(data.price.priceAfterDiscountNetto);
            totalBrutto = (data.cartItemQuantity * data.quantityInPackage) * parseFloat(data.price.priceAfterDiscountBrutto);
        } else {
            totalNetto = (data.cartItemQuantity * data.quantityInPackage) * parseFloat(data.price.priceBeforeDiscountNetto);
            totalBrutto = (data.cartItemQuantity * data.quantityInPackage) * parseFloat(data.price.priceBeforeDiscountBrutto);
        }
    }

    data.eans = data.eans.filter(value => value != data["default_ean"]);

    let promotion = false;

    if (data.price && (data.price.isPromoted || data.price.isUglyPromotion)){
        promotion = true;
    }

    return {
        id: data.id,
        name: (data.translation.name || data.hurtName),
        description: data.translation.description,
        slug: data.slug,
        indexHurt: data.hurtId,
        eans: data.eans.join(', '),
        ean: data["default_ean"],
        unit: t('products-list.'+toLower(data.unit)),
        quantityUnit: data.saleDepartmentSum + ' ' + t('products-list.'+toLower(data.unit)),
        quantity: data.saleDepartmentSum,
        quantityPackage: data.quantityInPackage,
        isSales: data.isSale,
        massive: data.isMassive,
        isNew: data.isNew,
        producerIndex: data["producer_index"],
        show_supplier_code: data.brand.show_supplier_code,
        notFullPacking: data.notFullPacking,
        isNoDiscount: data.isNoDiscount,

        promoted: promotion,
        priceBefore: parseFloat(data.price.priceBeforeDiscountNetto),
        priceBeforeBrutto: parseFloat(data.price.priceBeforeDiscountBrutto),
        priceNetto: parseFloat(data.price.priceAfterDiscountNetto),
        priceBrutto: parseFloat(data.price.priceAfterDiscountBrutto),
        discount: data.price.discount,
        promotionType: data.price.priceType,

        thumb,
        bigThumb,
        images,
        isGallery,

        ref: calculatedInputRef,

        producerName: data.producer.name,
        brandName: data.brand.name,
        licenseName: (data.license !== null ? data.license.name : '-'),

        cartItemQuantity: data.cartItemQuantity,
        totalNetto: parseFloat(totalNetto.toFixed(2)),
        totalBrutto: parseFloat(totalBrutto.toFixed(2)),
    };
}


// function useAdd2Cart(){
//     const {response, setBody} = Add2Cart();
//     const cartContext         = useContext<CartContext>(CartContext);
//     // const {cartItemAmount, setSend} = CartItemAmount();
//     // const cartItemNumberDOM         = document.querySelector('header .cart .number-items');
//
//     useEffect(() => {
//         if (response !== null && cartContext !== false){
//             cartContext.setCart(response);
//         }
//
//     }, [response, cartContext]);
//
//     // useEffect(() => {
//     //     if (cartItemAmount !== null){
//     //         cartItemNumberDOM.innerHTML = cartItemAmount.cart_item_amount;
//     //     }
//     // }, [cartItemAmount]);
//
//     function calculatedPackage(e, product){
//         const value = e.currentTarget.value;
//
//         add2Cart(product, value);
//
//         product.ref.current.value = (value * product.quantityPackage)+' '+ product.unit;
//     }
//
//     function add2Cart(product, quantity){
//         setBody({product_id: product.id, quantity: quantity});
//     }
//
//     return {calculatedPackage};
// }

export {useProductData, useAdd2Cart};
