import React, {ReactElement} from "react";
import {Tooltip} from "react-tooltip";

export const GenerateName = (originalName: string, maxLength: number, productId: number): ReactElement => {
    if (originalName.length > maxLength){
        return <>
            <Tooltip id={'product-name-'+productId} >{originalName}</Tooltip>
            {originalName.substring(0, maxLength)} <span className={'show-full-name'} data-tooltip-id={'product-name-'+productId} >...</span>
        </>;
    }

    return <>{originalName}</>;
}