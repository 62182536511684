import React, {ReactElement} from "react";
import {AlphabetListInterface} from "./Types";
import FilterItem from "./FilterItem";

export function AlphabetList({search, toggleState, propertyName}: AlphabetListInterface): ReactElement {
    const {setLetterToggle, letterToggle} = toggleState;

    return (
        <>
            {Object.keys(search.alphabetData).map((letter: any, key: number) => {
                return (
                    <li key={key} >
                        <button onClick={() => {
                            setLetterToggle((prevState: {[key: string]: boolean}) => {
                                return {...prevState, [letter]: !prevState[letter]};
                            });
                        }} className={'center'} >{search.alphabetData[letter].name}</button>

                        <ul className={letterToggle[letter] ? 'active' : undefined} >
                            {search.alphabetData[letter].children.map((filter: any, key2: number) => {
                                return <FilterItem
                                    propertyName={propertyName}
                                    filter={filter}
                                    key={key2}
                                />
                            })}
                        </ul>
                    </li>
                );
            })}
        </>
    );
}