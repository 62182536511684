import {Link, useLoaderData, useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import UserAccount from "../../../Components/UserAccount";
import {priceBuilder} from "../../../Hooks/Price"
import "../../../Assets/Css/Pages/User/Order/orderItem.css";
import {useTranslation} from "react-i18next";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";
import {SavedCartModal} from "../SavedCarts/TransferSavedCartToCart";
import {Throbber} from "../../../Components/Throbber";
import {Form} from "../Invoices/DownloadFile";
import moment from "moment";

function Order(){
    const loader = useLoaderData();
    const {id, type} = useParams();
    const [order, setOrder] = useState(loader);
    const {t} = useTranslation();
    const {user} = useContext(UserContext);
    const [throbber, setThrobber] = useState(false);

    if (user === null){
        return(<UserIsNotLogged />);
    }

    if (order === null){
        return(
            <></>
        );
    }

    function summary(){
        let sumNetto = 0;
        let sumBrutto = 0;

        order.partialOrder.map((orderPartial) => {
            orderPartial.orderItem.map((item) => {
                sumNetto += item.unitPrice * item.quantityInPackage;
            });
        });

        return {netto: sumNetto.toFixed(2)};
    }

    const createdAt = new moment(order.partialOrder[0].createdAt).format('YYYY-MM-DD');

    const breadcrumb = [{name:  t('user-page.order.title'), path: '/user/orders'}, {name: (id)?.replace('-', '/'), path: null}];

    return(
        <>
            <UserAccount title={ t('user-page.order.sub-title')+' '+order.partialOrder[0].hurtIdCanonical+' ('+order.partialOrder[0].externalNumberOrder1+')'} className="my-order" breadcrumb={breadcrumb} >
                <div className={'top-data'} >
                    <div className="types" >
                        <div className="type" >
                            <span>{t('user-page.invoice.contractor')}:</span>
                            <span>{order.partialOrder[0].receiver.contractor.name}</span>
                        </div>
                        <div className="type" >
                            <span>{t('user-page.invoice.receiver')}:</span>
                            <span>{order.partialOrder[0].receiver.name}</span>
                        </div>

                        <div className="type" >
                            <span>{t('user-page.invoices.date-create')}:</span>
                            <span>{createdAt}</span>
                        </div>
                        <div className="type" >
                            <span>{t('user-page.invoice.value')}:</span>
                            <span>{priceBuilder(order.partialOrder[0].total, order.partialOrder[0].currencyCode)} {t('products-list.before-tax')}</span>
                        </div>
                    </div>
                    <div className={'download-invoice'} >
                        <Throbber visible={throbber} />
                        <SavedCartModal setThrobber={setThrobber} endpoint={'/user/order/transform-to-cart'} idItem={order.partialOrder[0].hurtIdCanonical} />
                    </div>
                </div>

                <Items data={order.partialOrder} isPartial={true} />
                {order.fullOrder !== null ? <Items data={order.fullOrder} isPartial={false} /> : null}
            </UserAccount>
        </>
    );
}

/**
 * @param data
 * @param isPartial
 * @returns {JSX.Element}
 * @constructor
 */
function Items({data, isPartial}){
    const {t} = useTranslation();

    function fullOrderPartialHeader(externalNumberOrder1){
        return(
            <tr>
                <td colSpan="4" className="number-title" >
                    {t('user-page.order.external-number')}: {externalNumberOrder1}
                </td>
            </tr>
        );
    }

    function order(){
        return data.map((order, key1) => {
                return(
                    <tbody key={key1}  >
                        {isPartial === false ? fullOrderPartialHeader(order.externalNumberOrder1) : null}
                        {orderItems(order)}
                    </tbody>
                );
            });
    }

    function orderItems(order){
        return order.orderItem.map((orderItem, key2) => {

            return(
                <tr key={key2} >
                    <td>{key2+1}</td>
                    <td className="lp" >
                        {orderItem.product.isActive ?
                            <Link to={'/product/'+orderItem.product.productTranslation[0].slug} >{orderItem.productName}</Link>
                            :
                            <>{orderItem.productName}</>
                        }

                    </td>
                    <td className={'index'} >{orderItem.product.hurtId}</td>
                    <td className={'ean'} >{orderItem.product.ean}</td>
                    <td className="quantity" >{orderItem.quantityInPackage} {orderItem.product.unit}</td>
                    <td className="quantity" >{orderItem.quantityRealized !== 0 ? orderItem.quantityRealized : ''}</td>
                    <td className="unit-price" >{priceBuilder(orderItem.unitPrice, order.currencyCode)}</td>
                    <td className="unit-price" >{priceBuilder(orderItem.unitPrice, order.currencyCode)}</td>
                    <td className="total" >{priceBuilder((orderItem.unitPrice * orderItem.quantityInPackage), order.currencyCode)}</td>
                </tr>
            );
        });
    }

    function summary(orders){
        let sum = 0;

        orders.map((order) => {
            order.orderItem.map((item) => {
                sum += item.unitPrice * item.quantityInPackage;
            });
        });

        return sum;
    }

    function textForFullOrder(){
        return(
            <div className="its-full-order" >
                {t('user-page.order.full-order-title')}
            </div>
        );
    }


    return(
        <>
            {isPartial === false ? textForFullOrder() : null}
            <div className="responsive-table-wrapper" >
            <table className="table-data table-order" style={{ "--column-width" : "30%"}}>
                <thead>
                    <tr>
                        <th className="lp" >{t('user-page.order.lp')}</th>

                        <th className="name" >{t('user-page.order.product-name')}</th>

                        <th className="index" >{t('user-page.order.index')}</th>
                        <th className="ean" >{t('user-page.order.ean')}</th>

                        <th className="quantity" >{t('user-page.order.quantity')}</th>
                        <th className="quantity" >{t('user-page.order.realized')}</th>

                        <th className="quantity" >{t('user-page.order.price-after-discount-netto')}</th>

                        <th className="unit-price" >{t('user-page.order.unit-price')}</th>
                        <th className="total" >{t('user-page.order.total')}</th>
                    </tr>
                </thead>
                {order(data)}
            </table>
            </div>

            <div className="summary" >{t('user-page.order.total')}: {priceBuilder(data[0].total, data[0].currencyCode)}</div>
        </>
    );
}

export default Order;
