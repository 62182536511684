import React, {RefObject, useEffect, useRef} from "react";
import {DefaultFilterItem} from "./index";
import {MergeAlphabetical} from "../../Components/ScrollMenu/MergeAlphabetical";
import {Link, useParams} from "react-router-dom";
import { customWordBreak } from "./punctuation";

interface Props {
    data: DefaultFilterItem[]
}

interface Letter {
    id: number,
    name: string,
    children: Children[]
}

interface Children {
    id: number,
    name: string,
    products_quantity: number
}

function AlphabeticalFilter({data}: Props): React.ReactElement {
    const sortData      = new MergeAlphabetical(data, 'name').getMergedData() as Letter[];
    const {menuitem}    = useParams();

    let refs: { [key: string]: RefObject<any> } = {};

    Object.values(sortData).map((letter: Letter, key) => {
        refs[letter.name] = React.createRef();
    });

    return(
        <div className={'default-list'} >
            <Alphabet sortData={sortData} refs={refs}/>

            {
                Object.values(sortData).map((letter: Letter, letterKey: number) => {
                    return (
                        <div className={'letter'} key={letterKey}>
                            <div className={'title'} ref={refs[letter.name]} >{letter.name}</div>

                            <div className={'letter-filters'} >
                                {
                                    letter.children.map((child: Children, childKey: number) => {
                                        return (
                                            <div className={'filter'} key={letterKey+'-'+childKey} >
                                                <Link to={'/products-list/'+menuitem+'/'+child.id} >
                                                    <span className={'name'} >{customWordBreak(child.name)}</span>
                                                    <span className={'quantity'} >({child.products_quantity})</span>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

function Alphabet({sortData, refs}: {sortData: Letter[], refs: { [key: string]: RefObject<any> }}): React.ReactElement {

    const {letter} = useParams();

    useEffect(() => {
        if (letter){
            scrollTo(letter);
        }
    }, [refs]);

    function scrollTo(key: string): void {
        refs[key]?.current.scrollIntoView();
    }

    return (
        <div className={'letters-menu'}>
            {Object.values(sortData).map((letter: Letter, key) => {
                return <div key={key} className={'item'} onClick={() => scrollTo(letter.name)} >{letter.name}</div>;
            })}
        </div>
    );
}

export {AlphabeticalFilter};