import React, {ReactElement, useContext} from "react";
import {Link} from "react-router-dom";
import {FilterItemInterface} from "./Types";
import {ActiveMenuContext} from "../../Header";

export default function FilterItem({propertyName, filter}: FilterItemInterface): ReactElement {
    const activeMenuContext = useContext(ActiveMenuContext);

    return (
        <li className={'filter-item'} >
            <Link to={'/products-list/'+propertyName+'/'+filter.id} onClick={() => activeMenuContext?.setActiveMenu(false)} >
                {filter.name} <span>({filter.products_quantity})</span>
            </Link>
        </li>
    );
}