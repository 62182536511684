import React, {useEffect, useState} from "react";
import {GET} from "../../../../../Hooks/RequestV2";
import {FilterComponent} from "./FilterComponent";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {OptionProps, OptionsOrGroups} from "react-select";

interface ReceiverInterface {
    id: number,
    name: string,
    address: {
        street: string,
        postalCode: string,
        city: string
    }
}

interface Option {
    value: number|string,
    label: string
}

function Receiver(): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();
    const [receivers, setReceivers] = useState<Option[]>([]);

    useEffect(() => {
        GET('/user/receivers')
            .then((response) => response.json())
            .then((json: ReceiverInterface[]) => {

                const optionMapping = json.map((receiver: ReceiverInterface) => {
                    const label = receiver.name+' - '+receiver.address.street+', '+receiver.address.postalCode+' '+receiver.address.city;

                    return {value: receiver.id, label: label};
                })

                setReceivers([{value: "", label: t('user-page.payment.all-receivers')}, ...optionMapping]);
            });
    }, []);

    return (
        <FilterComponent title={t('user-page.invoice.filters.receiver')} className={'receiver'} >
            {receivers.length > 0 ?

                <Select
                    onChange={(receiver: Option) => form.setValue('receiver', receiver.value)}
                    placeholder={t('user-page.payment.all-receivers')}
                    options={receivers}
                />

                : ''}
        </FilterComponent>
    );
}

export {Receiver};