import { useEffect, useMemo, useState } from "react"

export default function useFloatingMobileHeader(threshold = 120, scrollTreshold = 1){
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(()=>{
        const handleScroll = (event: Event) => {
            setScrollTop(window.scrollY);
        }
        window.addEventListener("scroll",handleScroll);
        return ()=>window.removeEventListener("scroll",handleScroll);
    },[setScrollTop, scrollTop])


    const [scrollDirection, setScrollDirection] = useState(false);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = !(scrollY > lastScrollY);
        if (direction !== scrollDirection && (scrollY - lastScrollY > scrollTreshold || scrollY - lastScrollY < -scrollTreshold)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection);
      return () => window.removeEventListener("scroll", updateScrollDirection);

    }, [scrollDirection]);
  
    const visible = useMemo(()=> scrollTop > threshold && scrollDirection, [scrollTop, scrollDirection, threshold] )
    
    return {visible}
}