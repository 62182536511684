import React, {useContext, useEffect, useState} from "react";
import {useProductData} from "../ProductData";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAdd2Cart} from "../../../Requests/Cart";
import {CartContext} from "../../../Contexts/CartContext";
import {UserContext} from "../../../Contexts/UserContext";
import {ProductsListContext} from "../../../Contexts/ProductsListContext";
import {ListItemHelper} from "../ListItemHelper";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import {QuantityFields} from "../Components/QuantityFields";
import {IconsDesignation} from "../IconsDesignation";
import {QuantityDisposition} from "../Components/QuantityDisposition";
import {ChannelContext} from "../../../Contexts/ChannelContext";

function MatrixList({removeItem}){
    const {products} = useContext(ProductsListContext);
    const [data, setData] = useState(products);

    useEffect(() => {
        setData(products);
    }, [products]);

    return(
        <div className="matrix">
            {data.map((product, key) => {
                return <Item data={product} key={product.id} removeItem={removeItem} />
            })}
        </div>
    );
}

function Item({data, removeItem}){
    const {user}                = useContext(UserContext);
    const {setCart}             = useContext(CartContext);
    const {products, setProducts} = useContext(ProductsListContext);
    const channelContext        = useContext(ChannelContext);
    const product               = useProductData(data);
    const {t}                   = useTranslation();
    const {response, setBody}   = useAdd2Cart();
    const {visibility, toggleVisibleModal, setVisibility} = useSimpleModalState();

    const iconsDesignation      = new IconsDesignation(product, t);

    let name;

    if (product.name.length > 50){
        name = product.name.substring(0, 50) + ' ...';
    } else {
        name = product.name;
    }

    // useEffect(() => {
    //     if (response !== null){
    //         setCart(response);
    //     }
    // }, [response]);

    function displayPrice() {
        if (!user && channelContext?.displayPriceNotLogged === true){
            return <div className={'prices'} ><span>{t('products-list.disable-price-not-logged')}</span></div>
        }

        if (user && channelContext?.displayPriceLogged === true){
            return <div className={'prices'}><span>{t('products-list.disable-price-logged')}</span></div>
        }

        return (
            <div className="prices" >
                <div className="price-title" >
                    {/*<div>{t('products-list.price')}:</div>*/}
                    {product.priceNetto > 0 ? <div className="old-price" >{product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div> : ''}
                    {product.isNoDiscount ? <div className={'final-price'} >{t('products-list.final-price')}</div> : ''}
                </div>
                <div className="price-wrapper" >
                    {product.priceNetto > 0 ?
                        <>
                            <div className="netto" >{product.priceNetto.toFixed(2)} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.priceBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
                        </>
                        :
                        <>
                            <div className="netto" >{product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.priceBeforeBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
                        </>
                    }
                </div>
            </div>
        );
    }

    function displayQuantity() {
        if (!user && channelContext?.displayQuantityNotLogged === true){
            return <div><span>{t('products-list.disable-quantity-not-logged')}</span></div>
        }

        if (user && channelContext?.displayQuantityLogged === true){
            return <div><span>{t('products-list.disable-quantity-logged')}</span></div>
        }

        return (
            <div className="quantity" >
                <span>{t('products-list.avail')}:</span>
                <span><QuantityDisposition quantity={product.quantity} unit={product.unit} /></span>
            </div>
        );
    }

    return (
        <section className="product" >
            {iconsDesignation.getIcons()}
            {removeItem ? <button className={'btn red remove'} onClick={() => removeItem(product.id)} ><i className="fas fa-times" ></i> {t('cart.remove')}</button> : null}

            <div className="thumb" style={{backgroundImage: 'url('+product.thumb+')'}} onClick={() => toggleVisibleModal()} >
                <i className="fas fa-search-plus more" />
            </div>

            <SimpleModal visibility={visibility} hidden={toggleVisibleModal} className={'product-thumb-modal'} >
                <img src={product.bigThumb} className={'thumb-img'} />
            </SimpleModal>

            <Link to={'/product/'+product.slug} >
                <h2>{name}</h2>
            </Link>
            {displayPrice()}
            <div className="attributes" >
                <div className="index" >
                    <span>{t('products-list.index-et')}:</span>
                    <span>{product.indexHurt}</span>
                </div>
                <div className="ean" >
                    <span>{t('products-list.ean')}:</span>
                    <span>{product.ean !== null ? product.ean : '-'}</span>
                </div>
                {displayQuantity()}
                <div className="package" >
                    <span>{t('products-list.in-package')}:</span>
                    <span>{product.quantityPackage}</span>
                </div>
            </div>

            {user && channelContext?.displayPriceLogged === true ? null :
                <div className="add-to-cart" >
                    <QuantityFields data={{product, products, setProducts, setBody}} label={true} revers={true} />
                </div>
            }

        </section>
    );
}

export default MatrixList;
