import UserAccount from "../../../Components/UserAccount";
import {ChangeUserData} from "../../../Requests/User";
import {useContext, useEffect, useState} from "react";
import "../../../Assets/Css/Pages/User/MyAccount/index.css";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";
import {GET} from "../../../Hooks/RequestV2";
import {UserType} from "../../../Types/UserType";
import {ChannelContext} from "../../../Contexts/ChannelContext";
import {fileTypeData, fileTypes} from "../Invoices/DownloadFile";

/**
 * My Account view
 *
 * @returns {JSX.Element}
 */
function MyAccount(){
    const {user, setUser}                      = useContext(UserContext);
    const {changeUserData, setChangeUserData, changeUserDataHttpStatusCode}  = ChangeUserData();
    const {t}                                  = useTranslation();

    useEffect(() => {
        GET('/user')
            .then((response) => response.json())
            .then((json) => setUser(json));
    }, [changeUserDataHttpStatusCode]);

    function checkIfSuccessSendForm(){
        if (changeUserData === 'ok'){
            return(
                <div className="form-success" >
                    <i className="fas fa-check" ></i>
                    <span>{t('user-page.my-account.success-save-form')}</span>
                </div>
            );
        }
    }

    if (user === null){
        return (
            <UserIsNotLogged />
        );
    }

    return(<DOM {...{checkIfSuccessSendForm, user, setChangeUserData}} />);
}

/**
 * DOM Tree
 *
 * @param checkIfSuccessSendForm
 * @param user
 * @param setChangeUserData
 * @returns {JSX.Element}
 */
function DOM({checkIfSuccessSendForm, user, setChangeUserData}){
    const {t}                                               = useTranslation();
    const {register, handleSubmit, formState: { errors }, resetField, getValues, watch, setValue, setError, clearErrors}   = useForm();
    const channelContext = useContext(ChannelContext);
    const [invoiceMaxFormatError, setInvoiceMaxFormatError] = useState(false);

    useEffect(() => {
        if (channelContext?.isExportDepartment === false){
            register('notification_invoice', {value: false});
        }
    }, []);

    function validateMaxInvoiceFormats(event){
        const max = 3;
        let countChecked = 0;

        Object.values(watch().invoice_format).map((format, key) => {
            if (format){
                countChecked++;
            }
        });

        if (countChecked > max && event.target.checked){
            event.preventDefault();

            setValue(event.target.name, false);

            setInvoiceMaxFormatError(true);

            return;
        }

        setInvoiceMaxFormatError(false);
    }

    const breadcrumb = [{name: t('user-page.my-account.title'), path: null}];

    return(
        <UserAccount title={t('user-page.my-account.title')} className="my-account" breadcrumb={breadcrumb} >
            <div className="info-box" >
                <h2>{t('user-page.my-account.data')}</h2>

                <div className="attr" >
                    <span>{t('user-page.my-account.login')}</span>
                    <span>{user.username}</span>
                </div>
                <div className="attr" >
                    <span>Nazwa kontrahenta:</span>
                    <span>[{user.receiver.contractor.hurtId}] {user.receiver.contractor.name}</span>
                </div>
                <div className="attr" >
                    <span>{t('user-page.my-account.receiver-name')}</span>
                    <span>{user.receiver.name}</span>
                </div>
                <div className="attr" >
                    <span>{t('user-page.my-account.address')}</span>
                    <span>
                        {user.receiver.address.street}<br/>
                        {user.receiver.address.postalCode} {user.receiver.address.city}
                    </span>
                </div>
            </div>
            <div className="info-box" >
                <h2>{t('user-page.my-account.change-data')}</h2>
                <form onSubmit={handleSubmit(setChangeUserData)} >
                    {checkIfSuccessSendForm()}

                    <div className="field" >
                        <label>{t('user-page.my-account.login')}</label>
                        <input type="text" {...register('username', {value: user.username})} />
                    </div>
                    <div className="field" >
                        <label>{t('user-page.my-account.email')}</label>
                        <input type="email" {...register('email', {value: user.email})} />
                    </div>
                    <div className="field" >
                        <label>{t('user-page.my-account.password')}</label>
                        <input type="password" {...register('newPassword')} />
                    </div>
                    <div className="field" >
                        <label>{t('user-page.my-account.re-password')}</label>
                        <input type="password" {...register('rePassword')} />
                    </div>
                    {channelContext?.isExportDepartment === false && user.admin === false ?

                        <div className="field checkbox" >
                            <input type="checkbox" {...register('notification_invoice', {value: user.newInvoice})} />
                            <label>{t('user-page.my-account.invoice-notification')}</label>
                        </div>

                        : null}

                    <div className="field checkbox" >
                        <input type="checkbox" {...register('notification_order', {value: user.newOrder})} />
                        <label>{t('user-page.my-account.order-notification')}</label>
                    </div>

                    {watch().notification_invoice ?

                        <div className={'invoice-formats'} >
                            <h3>{t('user-page.my-account.invoice-formats-label')}:</h3>

                            {invoiceMaxFormatError && <p className={'error'} >{t('user-page.my-account.invoice-formats-error')}</p>}

                            {Object.values(fileTypes).map((file, key) => {
                                return(
                                    <div key={key} >
                                        <label>{file.label}</label>
                                        <input type={'checkbox'} {...register('invoice_format.'+file.format, {onChange: validateMaxInvoiceFormats, value: user.invoiceFormat[file.format]})} />
                                    </div>
                                );
                            })}
                        </div>

                        : null}

                    <div className="submit"  >
                        <button className="btn" type="submit" >{t('user-page.my-account.save')}</button>
                    </div>
                </form>
            </div>
        </UserAccount>
    );
}

export default MyAccount;
