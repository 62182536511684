import {Children, useContext, useState} from "react";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import FilterDefaultData from "../Pages/ProductsList/Tools/FiltersDefaultData.json";
import {collectDataForm} from "../Pages/ProductsList/FiltersDataHelper";
import {useParams, useSearchParams} from "react-router-dom";
import {IsMobileContext} from "../Contexts/IsMobileContext";
import {ScreenWidthContext} from "../Contexts/ScreenWidthContext";

function Filter({name, children, resetVisible, filterInputName, send}){
    const form                      = useFormContext();
    const {t}                       = useTranslation();
    const {filter, id}              = useParams();
    const [searchParams, setSearchParams]   = useSearchParams();
    const numberOfItemToHidden      = 10;
    const arrayChildren             = Children.toArray(children);
    const [visible, setVisible]     = useState(false);
    const more                      = t('layout.filters.more');
    const less                      = t('layout.filters.less');
    const isMobile                  = useContext(IsMobileContext);
    const screenWidth               = useContext(ScreenWidthContext);
    const [toggleFilter, setToggleFilter] = useState(false);

    function toggleVisible(){
        setVisible(!visible);
    }

    function resetFilter(e){
        filterInputName.map((value, key) => {
            form.resetField(value, {defaultValue: FilterDefaultData[value].emptyValue});
        });

        collectDataForm(form, {name: filter, id}, setSearchParams);
    }

    return(
        <>
            <div className="filter" >
                <div className="header" >
                    <h3 onClick={() => {
                        if (screenWidth < 1200){
                            setToggleFilter(!toggleFilter);
                        }
                    }} >{name}</h3>
                    {(resetVisible === true && toggleFilter && screenWidth < 1200) || (resetVisible === true && screenWidth > 1200)  ?
                        <button onClick={(e) => resetFilter(e)} >
                            <i className="fas fa-times" ></i> {t('layout.filters.reset-filter')}
                        </button>
                      : ''
                    }
                </div>

                <div className={'data'+(screenWidth < 1200 && toggleFilter ? ' active' : '')} >
                    <div className="options" >
                        {arrayChildren.map((value, key) => {
                            if (key <= numberOfItemToHidden){
                                return value;
                            }
                        })}
                    </div>
                    <div className={'hidden'+(visible === true ? ' open' : '')} >
                        {arrayChildren.map((value, key) => {
                            if (key > numberOfItemToHidden){
                                return value;
                            }
                        })}
                    </div>
                    {(children.length > 7 ? <button className="more" type="button" onClick={toggleVisible} >{(visible === true ? less : more)}</button> : null)}
                </div>
            </div>
        </>
    );
}

export default Filter;
