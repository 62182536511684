import { Table } from "./Table"
import "../Assets/Css/Components/responsive-table.css"
import { useCallback } from "react"

interface PropType {
    additionalBottom: string|null, 
    table: any,
    maxColumns: number|null
}


export default function ResponsiveTable({additionalBottom, table, maxColumns}: PropType){

    const calculateColumnWidth = useCallback((): string => {
        if(!maxColumns){ 
            return 10 + "%"
        }
        return Math.round(100/maxColumns) + "%"
    },[maxColumns])

    const style = { "--column-width" : calculateColumnWidth()} as React.CSSProperties
    return <div className="responsive-table-wrapper" style={style}><Table {...{additionalBottom, table }}/></div>
}