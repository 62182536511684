import React, {useContext, useEffect, useState} from "react";
import {ChannelContext}                   from "../../Contexts/ChannelContext";
import {LoginForm}                from "../../Forms/LoginForm";
import {useTranslation}                 from "react-i18next";
import {Dropdown, DropdownAnchor, DropdownItem, DropdownList} from "../../Components/Dropdown";
import {useCookie} from "../../Hooks/Cookies";
import {GET, POST} from "../../Hooks/RequestV2";
import {UserContext} from "../../Contexts/UserContext";
import {IsMobileContext} from "../../Contexts/IsMobileContext";

/**
 * The top of header with components like languages, currencies, login button, user menu.
 */
function HeaderTop() {
    const channel = useContext(ChannelContext);

    return (
        <div className="top" >
            <LoggedAsContractor />
            {channel.currency.length > 1 && <Currencies />}
            {channel.locales.length > 1 && <Languages />}
            <LoginForm />
        </div>
    );
}

function LoggedAsContractor() {
    const userContext = useContext(UserContext);

    if (userContext.user.admin){
        return (
            <div className={'logged-as-contractor'} >
                <span className={'title'} >Działasz w imieniu:</span> <span className={'value'} >[{userContext.user.receiver.contractor.hurtId}] {userContext.user.receiver.contractor.name}</span>
            </div>
        );
    }

    return <></>;
}

/**
 * Languages dropdown
 */
function Languages(){
    const channel                               = useContext(ChannelContext);
    const isMobileContext                       = useContext(IsMobileContext);
    const {t, i18n}                             = useTranslation();
    const [activeLanguage, setActiveLanguage]   = useState(i18nLocale);

    // useEffect(() => {
    //     if (!i18n.language){
    //         // changeLanguage(channel.locales[0]);
    //         // setActiveLanguage(i18nLocale());
    //     } else {
    //
    //     }
    // }, [channel]);

    // Return language code, name and API code version for actual language
    function i18nLocale(locale = ''){
        const code = (locale === '' ? (i18n.language).replace('-', '_') : locale.code);

        return {
            code: t('languages.'+code+'.code'),
            name: t('languages.'+code+'.name'),
            backendCode: code
        };
    }

    // Trigger for change language in dropdown
    function changeLanguage(locale){
        i18n.changeLanguage((locale.code).replace('_', '-')).then(()=>{
            setActiveLanguage(i18nLocale());
            localStorage.setItem('i18nextLng', (locale.code).replace('_', '-'));
            window.location.reload();
        });
    }

    if (activeLanguage === null){
        return <></>;
    }

    return(
        <Dropdown className="languages" >
            <DropdownAnchor>
                {isMobileContext ?

                    <span className={'flag-icon flag-icon-'+activeLanguage.code} ></span>

                    :

                    <>
                        <span className={'flag-icon flag-icon-'+activeLanguage.code} ></span>
                        {activeLanguage.name}
                    </>
                }

            </DropdownAnchor>

            <DropdownList>
                {channel.locales.map((locale, i) =>
                    (i18nLocale().backendCode !== locale.code ?
                        <DropdownItem key={i} clickEvent={() => changeLanguage(locale)} >
                            <span className={'flag-icon flag-icon-'+i18nLocale(locale).code} ></span>
                            {i18nLocale(locale).name}
                        </DropdownItem>
                        : null)
                )}
            </DropdownList>
        </Dropdown>
    );
}

/**
 * Currencies dropdown
 *
 * @returns {Dropdown}
 */
function Currencies(){
    const channel                   = useContext(ChannelContext);
    const userContext               = useContext(UserContext);
    const isMobileContext           = useContext(IsMobileContext);
    const {getCookie, setCookie}    = useCookie();
    const activeCurrency            = (getCookie('currency-code') === undefined ? 'pln' : getCookie('currency-code'));

    // Trigger to change currencies and add it to cookies & reload page
    function changeCurrency(currency){
        setCookie('currency-code', currency.code);
        setCookie('currency-fix', currency.fix);

        if (userContext.user){
            POST('/user/cart/check-price')
                .then((response) => {
                    if (response.status === 200){
                        const currentUrl = new URL(window.location.href);

                        currentUrl.searchParams.append('reload-cart-currency', 'true');

                        window.location.href = currentUrl.toString();
                    }
                });
        } else {
            window.location.reload();
        }
    }

    // Get current currency from cookies (if cookie is null - set default)
    function getCurrency(){
        const currencyCode  = getCookie('currency-code');
        const currencyFix   = getCookie('currency-fix');

        if (currencyCode === undefined){
            const firstCurrency = channel.currency[0];

            setCookie('currency-code', firstCurrency.code);
            setCookie('currency-fix', firstCurrency.fix);

            if (isMobileContext){
                return firstCurrency.fix;
            }

            return firstCurrency.code+' - '+firstCurrency.fix;
        }

        if (isMobileContext){
            return currencyFix;
        }

        return currencyCode+' - '+currencyFix;
    }

    return(
        <>
            <Dropdown className="currencies" >
                <DropdownAnchor>
                    {getCurrency()}
                </DropdownAnchor>

                <DropdownList>
                    {channel.currency.map((currency, i) =>
                        (currency.code !== activeCurrency ?
                            <DropdownItem key={i} clickEvent={() => changeCurrency(currency)} >
                                {currency.code} - {currency.fix}
                            </DropdownItem>
                            : null)
                    )}
                </DropdownList>
            </Dropdown>
        </>
    );
}

export {HeaderTop, Currencies, Languages};
